import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
//import AlConnects from "../../../assets/images/blog/Al-Connects-the-dots-for-digital-diagnosis.png"

export default () => 
<div>
    <SEO title={'How to Apply AI Technologies to Rare Disease Diagnosis | ICON Worldwide'} 
    description="Rare diseases affect less than 1% of the world’s population: the challenge is to increase the diagnosis' accuracy. Discover how AI technologies may help!"
    canonical={'https://icon-worldwide.com/blog/applying-ai-technologies-to-rare-disease-diagnosis'}/>

    <NewsHeader/>

    <div id="single-news">
        <h1><span>Digital Pharma:</span> How to Apply AI Technologies to Rare Disease Diagnosis</h1>
        <div id="title-underline"></div>

        <div id="single-news-texts">
        <p>Rare diseases are defined as those affecting less than 1% of the world’s population. The fundamental challenge in the diagnosis of rare diseases lies at the first point of patient contact, the house physician. 
        If, for example, a patient manifests symptom x, the physician will test for the top five potential illnesses related with that symptom. This physician will be almost completely unable to diagnose one 
        of the thousands of other potential illnesses which might be suspect.</p>
        <p>The challenge, therefore, is to increase the rate of accurate diagnosis of this specific disease. To do this we need to assist the house physician in making this diagnosis.</p>
        <h2>We have created several tools which are already in use which have the potential for massive improvement using AI technologies.</h2>
        <p>One tool is a differential diagnosis algorithm. This algorithm asks the physician to
        record symptoms a patient is demonstrating, from a complex and lengthy list, and then generates a list of potential illnesses. The algorithm presents a ranking of High Frequency, Low Frequency, Specific, etc. 
        As well, it will propose other tests to perform to narrow the possible disease results.</p>
        <p>The differential diagnosis is a widely used tool typically coming out of a clinical study which produced a data set. The most obvious role for AI is simply to continuously improve the accuracy of the algorithm as the data set increases.</p>
        <p>The algorithm could be applied to a physician’s normal reporting tools as they meet with patients in real time. For example, a physician examining a patient manifesting a set of three symptoms would receive notifications or alerts to consider
        further tests in order to narrow the diagnosis. Eventually, experts could be referred in for consultation based on certain combinations of symptoms.</p>
        <p>This combination of human and computer interaction is at the heart of current AI theory which focuses on AI as complementary to the human role, not replacing. In the process, the accuracy of the algorithm would improve exponentially resulting in
        improved diagnosis percentages based on massive scaling of the data set.</p>
        <p>Applied as an AI system, this differential diagnosis algorithm has the potential to spare the effort and cost associated with making physicians aware of and training them to use a specific tool. Multiple algorithms can be applied simultaneously 
        in order to broaden the set of potential diagnosis, and greatly improve the speed at which a given patient is correctly diagnosed.</p>
        <p>We believe the leaps needed to move from existing tools, such as differential diagnosis algorithms, to more advanced AI technologies is getting smaller by the day, and that the next 12 months will see AI technologies being applied in real practice.
        ICON Worldwide has been recognized as a <a  href="https://www.softwaredevelopmentcompany.co/healthcare-software-development-companies/" target="_blank" rel="noopener noreferrer"> Best Healthcare Software Development Company.</a></p>
        <p></p>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta">BOOK A MEETING NOW</Link></h4>

        <NewsFooter previous="icon-bulgaria-nearshore-development" next="sams-app-development-case-study"/>
    </div>

    <Footer noScrollbar="true"/>
</div>